<template>
  <div>
    <h3>Mijn gegevens</h3>
    <b-form @submit.prevent="updateSub">
      <b-row>
        <b-col sm="12" lg="10">
          <b-row>
            <b-col cols="12" class="pb-3">
              <label>
                Voornaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.firstname" :disabled="true" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                Achternaam
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.lastname" :disabled="true" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                E-mailadres
                <span class="text-danger">*</span>
              </label>
              <b-form-input v-model="form.email" :disabled="true" required></b-form-input>
            </b-col>
            <b-col cols="12" class="pb-3">
              <label>
                Telefoonnummer
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.tel"
                :disabled="true"
                oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                required
              ></b-form-input>
            </b-col>
            <b-col cols="12">
              <label>
                Gsm
                <span class="text-danger">*</span>
              </label>
              <b-form-input
                v-model="form.MobilePhone"
                :disabled="true"
                oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                required
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row v-if="false">
            <b-col class="text-right mt-3">
              <b-button variant="primary" type="submit">
                <font-awesome-icon icon="save" /> Wijzigingen opslaan
                <font-awesome-icon
                  v-if="processing"
                  class="fa-spin"
                  far
                  :icon="['fad', 'spinner']"
                />
              </b-button>
              <hr />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    <hr class="mb-4 mt-4">
    <user-change-password class="" />
  </div>
</template>
<script>
import { modifySub, getSubs } from '@/services/AuthorizationService'
import UserChangePassword from './UserChangePassword.vue'


export default {
  name: 'AccountUser',
  components: {
    UserChangePassword,

  },
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        username: '',
        firstname: '',
        lastname: '',
        tel: '',
        email: '',
        MobilePhone: '',
        password: '',
        passwordConfirm: ''
      },
      subUser: null,
      processing: false
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.subUsers
    },
    account() {
      return this.$store.state.account
    }
  },
  created: async function() {
    await getSubs()
    this.form.username = this.account.UserName
    this.form.firstname = this.account.FirstName
    this.form.lastname = this.account.LastName
    this.form.tel = this.account.Tel
    this.form.MobilePhone = this.account.MobilePhone
    this.form.email = this.account.Email

  },
  methods: {
    notify({ message }) {
      this.$toasted.show(message)
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      const result = await modifySub({
        email: this.form.email || '',
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel,
        MobilePhone: this.form.MobilePhone,
        CUST_ReceiveNewsletter: this.form.CUST_ReceiveNewsletter === 'accepted'
      })

      if (result !== null)
        this.notify({ message: 'Gegevens succesvol aangepast' })
      this.processing = false
    }
  }
}
</script>
